/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Cepeban from "@components/sharedComponents/customersLogos/logos/Cepeban.svg"
import CasaRossello from "@components/sharedComponents/customersLogos/logos/CasaRossello.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Cafae from "@components/sharedComponents/customersLogos/logos/Cafae.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/plataforma-ticker.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/kpi-customer-success.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/expectativas-cliente.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/interaccion-cliente.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/chatbots-ia-casos-de-uso.jpg"

// logos testimonios
import KontigoTes from "@components/sharedComponents/testimonies/images/logos/kontigo.svg"
import SavarTes from "@components/sharedComponents/testimonies/images/logos/savar.svg"
import CepebanTes from "@components/sharedComponents/testimonies/images/logos/cepeban.svg"
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"

//foto testimonio desktop
import kontigoDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/kontigo.png"
import savarDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/savar.png"
import cepebanDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cepeban.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"

//foto testimonio desktop
import kontigoMobile from "@components/sharedComponents/testimonies/images/fotosMobile/kontigo.png"
import savarMobile from "@components/sharedComponents/testimonies/images/fotosMobile/savar.png"
import cepebanMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cepeban.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageTicker/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageTicker/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageTicker/images/accordionLeft/imageThree.webp"

//image acordeon right
import ImageAccordionRightOne from "@components/pageTicker/images/accordionRight/imageOne.webp"
import ImageAccordionRightTwo from "@components/pageTicker/images/accordionRight/imageTwo.webp"
import ImageAccordionRightThree from "@components/pageTicker/images/accordionRight/imageThree.webp"
import ImageAccordionRightFour from "@components/pageTicker/images/accordionRight/imageFour.webp"
import ImageAccordionRightFive from "@components/pageTicker/images/accordionRight/imageFive.webp"


let data = {
  start: {
    textbody:
      "Hacemos que las empresas se contacten y comuniquen mejor con sus clientes, con soluciones omnicanales y tecnologías de voz. Aplicamos para todos, grandes operaciones de Contact Center y negocios que buscan escalar.",
    image: "./banner.html",
    textbutton: "Hablar con un experto",
    textlink: "Registrarme a una demo Beex",
    link: "/contact-center/",
  },
  customersDesktop: [
    Rimac,
    Auna,
    Unifef,
    Astara,
    Cepeban,
    CasaRossello,
    Proempresa,
    Savar,
    Cafae,
    Escampa,
    Royal,
    G4S
  ],
  customersTablet: {
    arrayOne: [Rimac, Auna, Unifef, Astara, Cepeban, CasaRossello],
    arrayTwo: [Proempresa, Savar, Cafae, Escampa, Royal, G4S],
  },
  articles: [
    {
      image: article1,
      title: "¿Qué es Beex Conversations y cuáles son sus funciones?",
      textbody:
        "Conoce cómo funciona esta plataforma, y cómo puedes empezar a crear tu cuenta para ofrecer una buena experiencia omnicanal.",
      link: "https://beexcc.com/blog/plataforma-ticker/",
      card: true,
    },
    {
      image: article2,
      title: "10 KPIs de customer success que debes conocer",
      textbody:
        "Conoce 10 KPIs de customer success y cómo se calcula cada uno con esta guía completa.",
      link: "https://beexcc.com/blog/kpi-customer-success/",
      card: true,
    },
    {
      image: article3,
      title: "¿Cómo mejorar las expectativas de un cliente? 7 consejos",
      textbody:
        "Descubre las mejores prácticas para mejorar las expectativas de un cliente con esta guía completa.",
      link: "https://beexcc.com/blog/expectativas-cliente/",
    },
    {
      image: article4,
      title: "Interacción con el cliente: 8 tips infalibles para mejorarla",
      textbody:
        "¿Cómo mejorar la interacción con el cliente? Descubre 8 estrategias para construir relaciones fuertes.",
      link: "https://beexcc.com/blog/interaccion-cliente/",
    },
    {
      image: article5,
      title: "Chatbots IA: 6 casos de uso para la atención al cliente",
      textbody:
        "Descubre cómo los chatbots IA pueden mejorar la atención del cliente con estos 6 casos de uso. ¡Entra aquí y construye relaciones duraderas!",
      link: "https://beexcc.com/blog/chatbots-ia-casos-de-uso/",
    },
  ],
  contacts: {
    title: "Empieza a mejorar la comunicación con tus clientes, ahora mismo",
    textbody:
      "Cuéntanos mejor sobre tu operación y te compartiremos feedback importante. Conoce porque Beex es la solución ideal para tu empresa y que resultados puedes lograr.",
    href: "/hablemos/",
    btn: " Comunícate con ventas",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: KontigoTes,
      title:
        "Kontigo cerró el 98% de casos en el primer contacto gracias a Beex y sus chatbots ",
      link: "/casos-de-exito/kontigo/",
      industrie: "Banca y finanzas",
      backgroundIndustrie: "#197DF2",
      backgroundFondo: "#197DF233",
      metrics: [
        {
          metric: "98%",
          descripcion: "cerrados en primer contacto",
          color: "#FFB800",
        },
        {
          metric: "<1 minuto",
          descripcion: "min primera respuesta",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "mejor resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    {
      image: CasaRosselloTes,
      title:
      "Casa Rosselló aumentó +75% sus leads digitales con Beex y WhatsApp API ",
      link: "/casos-de-exito/casa-rossello/",
      industrie: "Ecommerce",
      backgroundIndustrie: "#D60000",
      backgroundFondo: "#D6000033",
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      image: AstaraTes,
      title:
        "Astara triplicó ventas y redujo en 93% el esfuerzo de remarketing con Beex",
      link: "/casos-de-exito/astara/",
      industrie: "Concesionarias",
      backgroundIndustrie: "#231E36",
      backgroundFondo: "#231E3633",
      metrics: [
        {
          metric: "x3",
          descripcion: "ventas digitales crecieron",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "menos esfuerzo remarketing",
          color: "#5831D8",
        },
        {
          metric: "80%",
          descripcion: "mejor contacto con leads",
          color: "#00CA56",
        },
      ],
    },
    {
      image: SavarTes,
      title:
        "Savar mejoró +20% la satisfacción de clientes con notificaciones por WhatsApp",
      link: "/casos-de-exito/savar/",
      industrie: "Logística",
      backgroundIndustrie: "#004F96",
      backgroundFondo: "#004F9633",
      metrics: [
        {
          metric: "+20%",
          descripcion: "más satisfacción",
          color: "#FFB800",
        },
        {
          metric: "+30%",
          descripcion: "menos consultas repetidas",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejor tiempo de resolución",
          color: "#00CA56",
        },
      ],
    },
    {
      image: CepebanTes,
      title: "CEPEBAN aumentó +27% su conversión de leads con Beex y WhatsApp API",
      link: "/casos-de-exito/cepeban/",
      industrie: "Educación",
      backgroundIndustrie: "#FF1A2B",
      backgroundFondo: "#FF1A2B33",
      metrics: [
        {
          metric: "+27%",
          descripcion: "mejor tasa de conversión",
          color: "#FFB800",
        },
        {
          metric: "+1.5k",
          descripcion: "leads al mes con chatbots",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejor resolución de casos",
          color: "#00CA56",
        },
      ],
    },
  ],
  accordionLeft: [
    {
      title: "Agentes IA",
      description: "<p>Diseña agentes potenciados con nuestra integración con Open AI y crea flujos en minutos, ya sea para FAQs, procesos de prospección en marketing o para autogestión de tus clientes con datos integrados desde tu CRM o por API.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Hilo omnicanal",
      description: "<p>Unifica las interacciones de voz, WhatsApp, correo, redes sociales y más en un solo hilo de conversación. Ayuda oportunamente a tus clientes gracias a la vista 360 que Beex te brinda de ellos en tiempo real.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Campañas masivas",
      description: "<p>Envía campañas masivas por WhatsApp o SMS en minutos. Importa tu base de contactos rápidamente o integra los envíos vía API para enviar campañas de marketing u otros, como ofertas, promociones especiales, recordatorios o actualizaciones.</p>",
      image: ImageAccordionLeftThree,
    },
  ],
  accordionRight: [
    {
      title: "Primer y segundo contacto por voz y WhatsApp",
      description: "<p>Para los equipos de marketing y ventas, lanza llamadas automáticas a tus leads ni bien dejen su información en tu formulario de contacto, a través de números de alta reputación para mejorar la tasa de respuesta. Y, si no contestan, envía una plantilla automática de WhatsApp para asegurar el recontacto.</p>",
      image: ImageAccordionRightOne
    },
    {
      title: "Confirmación de compras y seguimiento de abandono por WhatsApp",
      description: "<p>Para los casos de ecommerce, notifica a tus clientes sobre el estado de sus compras. Automatiza estos procesos con los eventos de tu plataforma de ecommerce e, incluso, disminuye el porcentaje de abandono de tu carrito de compras enviando mensajes a tus clientes potenciales que no terminaron el flujo de venta.</p>",
      image: ImageAccordionRightTwo
    },
    {
      title: "Seguimiento de envíos en tiempo real por WhatsApp o SMS",
      description: "<p>Para las operaciones de logística, mejora la experiencia de tus clientes y aumenta considerablemente tu CSAT al permitirles darle seguimiento a sus envíos en tiempo real. Hazlo por WhatsApp o SMS, enviando plantillas que detallen la ubicación en tiempo real o con un link de seguimiento.</p>",
      image: ImageAccordionRightThree,
    },
    {
      title: "Reserva y confirmación de citas por WhatsApp",
      description: "<p>Para casos donde tus usuarios deban agendar citas según disponibilidad, como citas médicas, visitas a propiedades, pruebas de manejo, etc. Usa agentes IA que las agenden sin intervención de un agente. Confirma estas citas con días de anticipación o dale opciones como reagendarla si es que es necesario.</p>",
      image: ImageAccordionRightFour,
    },
    {
      title: "Envío de códigos de acceso por correo electrónico o WhatsApp",
      description: "<p>Si brindas acceso a cuentas en línea, refuerza la seguridad de tu proceso enviando códigos únicos a tus clientes por correo electrónico o WhatsApp. Autentica a tus usuarios para verificar cuentas, recuperar accesos o cambios de contraseñas.</p>",
      image: ImageAccordionRightFive,
    },
  ],
}

export default data
